
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@800&display=swap');

form {
  color: white;
}

.App {
  text-align: center;
  background-color: rgb(40, 119, 93);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-left {
    animation: App-logo-spin infinite 20s linear;
  
    
  }
  .App-logo-right {
    animation: App-logo-spin infinite 20s lineasar;

  }
}


.App-header {

  min-height: 200vh;
  display: flex;
  flex-direction: column;
 

  font-size: calc(10px + 2vmin);
  color: black;

 
  #logo 
  {
  float: left;
  justify-content: top;
  height: 18%; 
  width: 18%;
  }

  table {
    border: 2px solid black; 
    width: 500px; 
    height: 500px; 
    float: right; 
    overflow-y: scroll;
    height: 1000px;
  }
  
  .Tax-brackets{
    overflow-y: scroll;
    height: 1000px;
  }
  
  th {
    justify-content: top;
    border: 2px solid black;
  }
  
  td {
    border: 2px solid black;
  }

  
  
}






.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



